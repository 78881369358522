<template>
  <b-card title="PO Fail List ">
    <b-modal v-model="disableButton" centered no-close-on-backdrop hide-footer hide-header>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Processing Purchase Order</p>
      </div>
    </b-modal>
      <b-modal v-model="modalProcess" title="Process Fail Purchase Order">
        <!-- Alert before Delete PO-->
        <b-container>
          <b-row>
            <b-col cols="12" >
              <div class="d-flex">
                Are you sure want to process this {{selectPO.PONumber}} ?
              </div>
            </b-col>
          </b-row>
        </b-container>
  
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="danger" class="ml-1" @click="confirmRemovePoFail">Remove PO</b-button>
          </div>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="confirmProcess">Create New PO</b-button>
          </div>
        </template>
      </b-modal>
      <!-- End of Modal Needed on this page-->
      <b-col cols="12">
        <b-row>
          <b-col cols="4"/>
          <b-col cols="4"/>
          <b-col cols="4">
            <b-form-input
              @input="(value) => doFiltering(value)"
              v-model="search"
              type="search"
              placeholder="Type to Search"
            />
          </b-col>
  
          
          <b-col style="margin: 15px 0 0 0" cols="12">
            <b-table
              class="custom-row-height"
              :busy.sync="tableLoading"
              show-empty
              :items="mappedPurchase"
              :fields="fields"
              
              responsive
            >
              <template #cell(createdAt)="row">
                {{dates(row.item.createdAt)}}
              </template>
              <template #cell(actions)="row">
                <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                  <template #button-content>
                    <feather-icon size="1x" icon="MenuIcon" />
                  </template>
                  <b-dropdown-item style="text-align:center">
                    <b-button size="sm" @click="detailPO(row)" variant="info" class="mr-1">
                      <feather-icon icon="EyeIcon"/>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item style="text-align:center" v-if=" permission.process">
                    <b-button size="sm" @click="processFailPo(row.item)" variant="outline-primary" class="mr-1">
                      Process
                    </b-button>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!--<step-progress :length="3" :finished="3" :returned="true"></step-progress>-->
          </b-col>
          <b-col cols="12">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              style="float: right"
            />
          </b-col>
        </b-row>
        
      </b-col>
      
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {userAccess,dateFormat} from '@/utils/utils'
import lodash from 'lodash';
import axios from '@/axios';
import stepProgress from '@/components/StepProgress.vue'

export default {
  components: {
    stepProgress
  },
  data() {
    return {
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'rspOrderNumber', label: 'Order Number', filterable: true, sortable: true },
        { key: 'customerPo', label: "Customer PO", filterable: true, sortable: true},
        // { key: 'fullfimentType', label: 'Fullfillment Type', filterable: true, sortable: true },
        { key: 'dealer.dealerCode', label: 'Dealer Code', filterable: true, sortable: true },
        { key: 'dealer.dealerName', label: 'Dealer Name', filterable: true, sortable: true },
        { key: 'sapSo', label: 'Sap So', filterable: true, sortable: true },
        { key: 'orderStatus', label: 'Status', filterable: true, sortable: true, },
        { key: 'deliveryType', label: 'Delivery Type', filterable: true, sortable: true },
        { key: 'createdAt', label:'PO Created Time', filterable: true, sortable: true},
        
      ],
      search: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      modalProcess: false,
      selectPO:'',
      disableButton: false,
    }
  },
  mounted() {
    this.$store.dispatch("dealer/getCurrentDealer"); 
    this.$store
      .dispatch('auth/fetchUser')
      .catch(err => console.log(err))
    
    this.getFailPurchase({ page:this.currentPage, entry:this.perPage,orderStatus: 'ALL', allAccess: this.permission.all })
    .then((data) => this.processMetadata(data.metadata));
      
  },
  computed: {
    ...mapGetters({
      purchase: 'purchase/failPurchase',
    }),
    mappedPurchase(){
      return this.purchase.map(item => {
        return {
          ...item,
          rspOrderNumber: item.PONumber.includes('*') ? item.PONumber.substring(0, 11) : item.PONumber,
          customerPo: item.PONumber.includes('*') ? item.PONumber.substring(12) : ""
        }
      })
    },
    currentDealer() {
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"]
      
      if(tempDealer === null || tempDealer === undefined){
        tempDealer = {
          status : "Not Allowed"
        }
      }else{
        tempDealer = {
          status : "Allowed"
        }
      }
      return tempDealer
    },
    user() {
      return this.$store.getters['auth/getActiveUser'];
    },
    permission() {
      console.log(userAccess("PO Fail", "order_menu"));
      
      return userAccess("PO Fail", "order_menu");
    },
  },
  methods: {
    ...mapActions({
      getFailPurchase: 'purchase/getFailPurchase',
      removePoFail: 'purchase/removePoFail',
      addSale: "sales/addSale",
    }),
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.getFailPurchase({ 
        search: (this.search!="")?this.search:undefined,
        page: this.currentPage, 
        entry: this.perPage
        ,orderStatus: 'ALL', allAccess: this.permission.all
      }).then((data) => this.processMetadata(data.metadata));
    }, 500),
    dates(date) {
      return dateFormat(date);
    },
    onPageChange(page) {
      this.currentPage=page;

      this.getFailPurchase({ 
        search: (this.search!="")?this.search:undefined,
        page: this.currentPage, 
        entry: this.perPage
        ,orderStatus: 'ALL', allAccess: this.permission.all
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    detailPO(row) {
      let idOrder = row.item._id;
      this.$router.push({ name: 'order-detail', params: {  id: idOrder,from:'/po-fail' } });
    },
    processFailPo(row) {
      this.selectPO = row;
      console.log("this.selectPO", this.selectPO);
      
      this.modalProcess = true;
    },
    confirmRemovePoFail(){
      this.removePoFail(this.selectPO._id)
      .then((response) => {
        console.log("response", response);
        this.modalProcess = false;
        this.$bvToast.toast("Remove PO Failed Number "+this.selectPO.PONumber+" Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        this.getFailPurchase({ page:this.currentPage, entry:this.perPage,orderStatus: 'ALL', allAccess: this.permission.all })

      })
      .catch((error) => {
        console.log("error", error);
        this.modalProcess = false;
        this.$bvToast.toast("Remove PO Failed Number "+this.selectPO.PONumber+" Failed", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
      })
    },
    confirmProcess(){
      this.disableButton = true
      this.modalProcess = false
      let mFormCopy = {
        addressType : "",
        customer : this.selectPO.customerDetail,
        customerPo : this.selectPO.PONumber.includes('*') ? this.selectPO.PONumber.substring(12) : "",
        dealer : this.selectPO.dealer,
        deliveryAddress : this.selectPO.deliveryAddress,
        deliveryAddress2 : this.selectPO.deliveryAddress2,
        deliveryAddress3 : this.selectPO.deliveryAddress3,
        deliveryCity : this.selectPO.deliveryCity,
        deliveryDate : this.selectPO.deliveryDate,
        deliveryEmail : this.selectPO.deliveryEmail,
        deliveryInstruction : this.selectPO.deliveryInstruction,
        deliveryPhone : this.selectPO.deliveryPhone,
        deliveryPostal : this.selectPO.deliveryPostal,
        deliveryType : this.selectPO.deliveryType,
        distChan : this.selectPO.distributionChannel,
        items : this.selectPO.items,
        orderType : this.selectPO.orderType,
        priority : this.selectPO.priority,
        remarks : this.selectPO.remarks,
        subTotal : "",
        tax : "",
        timeslot : this.selectPO.timeslot,
        totalPrice : "",
        failed: true
      }
      this.addSale(mFormCopy)
      .then(async (response) => {
        let { data } = response;
        //ON HOLD, check respon from SAP
        // console.log(data)
        if (data.apiResult.type === "S") {
          this.$bvToast.toast("Create Sales Order Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "order" });
        } else {
          this.$bvToast.toast("There is a technical issue during your Purchase Order submission. We are looking into this matter and will update you via email or phone call. Please not re-submit the Purchase Order.", {
            title: "Failed",
            variant: "danger",
            solid: true,
            noAutoHide: true, // Menambahkan properti ini untuk tidak menutup otomatis
          });
        }
        this.disableButton = false
        this.disableButton = false;
        await sleep(2000);
      })
      .catch(async (e) => {
        this.disableButton = false;
        if (e.response.data !== undefined && e.response.status !== 504) {
          this.$bvToast.toast("There is a technical issue during your Purchase Order submission. We are looking into this matter and will update you via email or phone call. Please not re-submit the Purchase Order.", {
            title: "Failed",
            variant: "danger",
            solid: true,
            noAutoHide: true, // Menambahkan properti ini untuk tidak menutup otomatis
          });
          this.disableButton = false
        }else{
          this.$bvToast.toast("There is a technical issue during your Purchase Order submission. We are looking into this matter and will update you via email or phone call. Please not re-submit the Purchase Order..", {
            title: "Failed",
            variant: "danger",
            solid: true,
            noAutoHide: true, // Menambahkan properti ini untuk tidak menutup otomatis
          });
          this.disableButton = false
        }
        await sleep(2000);
        // this.$router.push({ name: "order" });
      });
    },
  },
  created() {
    document.title = 'PO fail | RSP';

    
    this.getFailPurchase({ page:this.currentPage, entry:this.perPage,orderStatus: 'ALL', allAccess: this.permission.all })
    .then((data) => this.processMetadata(data.metadata));
  },
}
</script>
<style lang="scss">
 .custom-row-height {
    min-height: 120px; /* Adjust this value as needed */
  }
</style>
